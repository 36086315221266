import { 
    useOutletContext 
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';

import {
    Box,
    Container,
    Grid,
} from '@mui/material';

import {
    renderFormElement,
} from './renderFormElement.js'

export function SocialServices() {

    const { t } = useTranslation();
    const {socialServices, setSocialServices} = useOutletContext();
    const [flags, setFlags] = useState({});

    const userData = [
        {label: 'label.socialServicesExplanation', key: 'socialServicesExplanation', type: 'title'},
        {label: 'label.saveDossierRemember', key: 'saveDossierRemember', type: 'title'},
    ];

    const setValueHandler = (key) => {
        return (value) => {
            socialServices[key] = value;
            setSocialServices(JSON.parse(JSON.stringify(socialServices)));
        };
    }

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    {userData.map((entry) => (
                        <Grid item xs={12} sm={entry.width || 12 } key={entry.key} >
                            {renderFormElement(t, socialServices, entry, setValueHandler)}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}